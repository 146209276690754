import {useContext, useEffect, useState} from "react";
import {useParams, useNavigate} from 'react-router-dom';
import {APIContext} from "../contexts/APIContext";
import {Button, Header, Message, MessageContent, MessageHeader, Modal, ModalContent} from "semantic-ui-react";
import {LanguageContext} from "../contexts/LanguageContext";
import {UserContext} from "../contexts/UserContext";
import {authenticationStore} from "@ametektci/ametek.stcappscommon";

export function JoinOrg() {
    const translate = useContext(LanguageContext)
    const api = useContext(APIContext)
    const userContext = useContext(UserContext)
    const params = useParams()
    const navigate = useNavigate()
    const [joinFinished, setJoinFinished] = useState(false)
    const [problem, setProblem] = useState("")
    const joinOrg = () => {
        api.joinOrg(params.code as string).then(([success, msg]) => {
            setJoinFinished(true)
            setProblem(msg)
            if (success) {
                userContext.reload().then(() => navigate("/"))
            }
        })
    }
    useEffect(() => {
        if (params.code)
            joinOrg()
    }, [params, params.code])
    //These all require join finished to be true

    return <Modal open={true} onClose={() => navigate("/")}>
        <Modal.Header>
            <Header>
                {translate.getString("joinExistingOrganization")}
            </Header>
        </Modal.Header>
        <JoinOrganizationModalBody problem={problem} joinFinished={joinFinished}/>
        <JoinOrganizationModalActions joinFinished={joinFinished}/>
    </Modal>
}

interface JoinOrganizationModalBodyProps {
    problem: string
    joinFinished: boolean
}

function JoinOrganizationModalBody(props: JoinOrganizationModalBodyProps) {
    const translate = useContext(LanguageContext)
    
    let message: string
    switch (props.problem) {
        case "BAD_CODE":
            message = translate.getString("requestANewLink")
            break
        case "NEEDS_ADMIN":
        case "REQUIRES_CLEANUP":
            message = translate.getString("assignAnotherAdminFirst")
            break
        case "":
            message = ""
            break;
        default:
            message = translate.getString("unknownProblem")
    }

    if (!props.joinFinished)
        return (
            <ModalContent>
                <Header size={"small"} textAlign={"center"}>
                    {translate.getString("joiningOrganization")}
                </Header>
            </ModalContent>
        )
    return (
        <Modal.Content>
            <Message error={true}>
                <MessageHeader>
                    {translate.getString("failedToJoin")}
                </MessageHeader>
                <MessageContent>
                    {message}
                </MessageContent>
            </Message>
        </Modal.Content>
    )
}
interface JoinOrganizationModalActionsProps {
    joinFinished : boolean
}
function JoinOrganizationModalActions(props: JoinOrganizationModalActionsProps) {
    const userContext = useContext(UserContext)
    const translate = useContext(LanguageContext)
    const navigate = useNavigate()
    return (
        <Modal.Actions>
            <Button content={translate.getString("signOut")} onClick={() => {
                authenticationStore.signOut()
                navigate("/")
            }}/>
            {userContext.organizationName ?
                <Button onClick={() => navigate("/")} loading={!props.joinFinished}>{translate.getString("returnToCrystalControlWeb")}</Button> :
                <Button onClick={() => navigate("/join/")} loading={!props.joinFinished}>{translate.getString("createNewOrganization")}</Button>
            }
        </Modal.Actions>
    )
}