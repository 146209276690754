import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {GaugeBase} from "../Models/GaugeBase";
import {
    authenticationStore,
    Log,
    messageQueue,
    SimpleVersion
} from "@ametektci/ametek.stcappscommon";
import {ConfigBase} from "../Models/Config/ConfigBase";
import {APIContext} from "./APIContext";
import deviceHubConnection from "../signalr/DeviceHubConnection";
import {ConnectedDevice} from "../Models/ConnectedDevice";
import {PropertiesFromDeviceAgent} from "../Models/PropertiesFromDeviceAgent";
import {DemoData} from "../utils/DemoData";
import localeStore from "../language/LocaleStore";
import {LanguageContext} from "./LanguageContext";
import {HPC50} from "../Models/Gauges/HPC50";
import {XP3I} from "../Models/Gauges/XP3I";
import {XP2I} from "../Models/Gauges/XP2I";
import axios, {AxiosResponse} from "axios";
import {OrganizationRequest, OrganizationResponse} from "../Models/Requests/OrganizationRequest";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";
import polly from "polly-js"
import {UserContext} from "./UserContext";
import {UpdatesContext} from "./UpdatesContext";

export const GaugesContext = createContext({
    gaugesLoaded: false,
    gauges: [] as Array<GaugeBase>,
    demoMode: false,
    getPermissions: (sn: string) => {return [] as Array<string>},
    getConfig: (sn: string) => {
        return undefined as ConfigBase | null | undefined
    },
    getGauge: (sn: string) => {
        return undefined as GaugeBase | undefined
    },
    setDemoMode: (demoMode: boolean) => {
    },
    updateGauge: (gauge: GaugeBase) => {
    },
    setConfig(config: ConfigBase, Sn: string) {
    },
    firmwareUpdateAvailable(type: string, firmware: string){ return false as boolean },
})
let connectedToHub = false;
export function GaugesContextWraper(props: PropsWithChildren) {
    const updatesContext = useContext(UpdatesContext)
    const userContext = useContext(UserContext)
    const api = useContext(APIContext)
    const translate = useContext(LanguageContext)
    const [gaugesLoaded, setGaugesLoaded] = useState(false) //Used to distinguish between the org has no gauges and the org is not loaded
    const [configs, setConfigs] = useState<{ [sn: string]: ConfigBase | null }>({})
    const [demoConfigs, setDemoConfigs] = useState(DemoData.config)
    const [inDemoMode, setInDemoMode] = useState(false)
    const [mainGauges, setMainGauges] = useState<GaugeBase[]>([])
    const [demoGauges, setDemoGauges] = useState<GaugeBase[]>(DemoData.gauges)
    const [gaugePermissions, setGaugePermissions] = useState<{[sn: string] : Array<string>}>({})
    useEffect(() => {
        connectToSignals()
        deviceHubConnection.on("ConnectionStatusChange",checkIfReady)
        return () => {
            deviceHubConnection.off("ConnectionStatusChange",checkIfReady)
            breakSignalConnections()
        }
    })
    const checkIfReady = () => {
        if (!gaugesLoaded)
            return
        if (deviceHubConnection.connection?.state == "Connected")
        {
            onSigRListening()
        }
        else
        {
            onSigRConnectionBroken()
        }
    }
    useEffect(() => {
        checkIfReady()
    }, [gaugesLoaded])
    useEffect(() => {
        if (userContext.user == null)
            return
        deviceHubConnection.startConnection().then(loadOrgGauges)
        return () => {
            setMainGauges([])
            setGaugesLoaded(false)
        }
    }, [userContext.user?.email, userContext.accessLevel, userContext.organizationName])
    //This is using the wrong endpoint, as it gets the entire organization rather than just the gauges.
    //I'll fix this in a later pass.
    //Doing so will work well with getting just the logs on their own.
    const loadOrgGauges = async () => {
        await polly()
            .logger(err => {
                console.error("Error loading organization gauges", err)
            })
            .handle((err) => {
                if (err.response) {
                    return err.response.status !== 403 && err.response.status !== 404
                }
                return true;
            })
            .waitAndRetry([50,100,200])
            .executeForPromise(async () => {
                return axios.post<OrganizationResponse, AxiosResponse<OrganizationResponse>, OrganizationRequest>(GetEnvironmentSetting('CcwApiUrl') + "/Organization", {},
                    {
                        headers: {
                            "Authorization": "bearer " + await authenticationStore.getAccessToken()
                        }
                    }
                )
            })
            .then( (response) => {
                let gauges = response.data.gauges.map(g => convertGaugeToCorrectType(g as GaugeBase))
                setMainGauges(gauges)
                setGaugesLoaded(true)
            })
            .catch(() => {
                console.error("Unable to get organization")
                setMainGauges([])
            })
    }
    const switchDemoMode = (newDemoMode: boolean) => {
        //can be removed when organizationStore no longer knows about DemoMode
        if (newDemoMode != inDemoMode) {
            setInDemoMode(newDemoMode)
        }
    }
    const updateGauge = (gauge: GaugeBase) => {
        if (inDemoMode) {
            setDemoGauges(dg => dg.map(g =>
                g.serialNumber == gauge.serialNumber ?
                    gauge : g))
        }
        else 
            setMainGauges(mg => mg.map(g =>
                g.serialNumber == gauge.serialNumber ?
                    gauge : g
            ))
    }
    const getGauges = () => {
        if (inDemoMode)
            return demoGauges
        return mainGauges
    }
    const getPermissions = (sn: string) => {
        if (!(sn in gaugePermissions))
        {
            navigator.locks.request(`permissions_${sn}`, {ifAvailable: true}, async (lock) => {
                if (lock == null)
                    return
                let permissions = await api.getGaugePermissions(sn)
                console.log(permissions)
                if (permissions.status == 200)
                    setGaugePermissions(per => ({...per, [sn]:permissions.data ?? []}))
            })
        }
        return gaugePermissions[sn] ?? []
    }
    const getConfig = (sn: string) => {
        if (inDemoMode) {
            return demoConfigs[sn]
        }
        if (sn == undefined)
            return null
        if (sn in configs)
            return configs[sn]
        //ifAvailable actually helps a lot when working with react. If we wait, we will still be looking at the older object to figure out whether or not the request has been filled.
        //It's not quite perfect (we still get multiple requests if we have a sensor multiple times in the recent logs section)
        //But it's a massive improvement!
        navigator.locks.request("CONFIG_"+sn, {ifAvailable: true}, async (lock) => {
            if (lock == null)
                return
            let foundConfig = await api.getGaugeConfig(sn)
            setConfigs((newConfigs) => ({...newConfigs, [sn]: foundConfig}))
        })
        
        return null
    }
    const setConfig = (config: ConfigBase, serial: string) => {
        updateGauge({
            ...getGauge(serial)!,
            calibrationDate: config.calibration.calibrationDate,
            calibrationDue: config.calibration.calibrationDue,
            name: config.name
        })
        if (inDemoMode) {
            setDemoConfigs({...demoConfigs, [serial]: config})
            return
        }
        setConfigs({...configs, [serial]: config})
    }
    const getGauge = (sn: string) => {
        return getGauges().find(g => g.serialNumber == sn)
    }
    const convertGaugeToCorrectType = (gauge: GaugeBase) => {
        switch (gauge.model.shortName.toLocaleUpperCase()) {
            case "HPC50":
                return new HPC50(gauge)
            case "XP3I":
                return new XP3I(gauge)
            case "XP2I":
                return new XP2I(gauge)
            default:
                return new HPC50(gauge)
        }
    }
    const setGaugeDisconnected = (gauge: GaugeBase) => {
        return {
            ...gauge,
            connected: false,
        }
    }
    const disconnectAll = () => {
        setMainGauges(mg => mg.map(g => setGaugeDisconnected(g)))
        //We disconnect all gauges when we can no longer trust the information we have to be accurate and up to date.
        //Clearing the stashed configurations ensures we don't load an old configuration while in this state.
        setConfigs({})
    }
    /*
        ### Signal R Connections
     */
    const mapConnectedProperties = (stored: GaugeBase, connected: GaugeBase, daVersion: string, daConnection: any, locked: boolean | undefined) : GaugeBase => {
        return {
            ...stored,
            connected: true,
            connectedDeviceAgentVersion: new SimpleVersion(daVersion),
            deviceAgentConnectionId: daConnection,
            firmwareVersion: connected.firmwareVersion,
            model: connected.model,
            calibrationDate: connected.calibrationDate,
            calibrationDue: connected.calibrationDue,
            dataLogEnabled: connected.dataLogEnabled,
            name: connected.name,
            locked: locked,
        }
    }

    function AnnounceConnected(connectedGauge: GaugeBase) {
        if (gaugesLoaded)
            messageQueue.sendSuccess(
                localeStore.Strings.deviceModelIsConnected.replace("###", connectedGauge.model.shortName).replace("***", connectedGauge.name ?? ""),
                localeStore.Strings.deviceConnected);
    }

    const deviceConnected = (connectedGauge: GaugeBase, Logs: Array<Log>, gaugeConfig: ConfigBase, deviceAgentVersion: string, deviceAgentConnectionId: any, properties: PropertiesFromDeviceAgent | undefined) => {
        if (gaugeConfig != null)
            setConfigs((conf) => ({
                ...conf, [connectedGauge.serialNumber]: gaugeConfig
            }))
        setMainGauges((mainGaugeList) => {
            let gaugeList;
            if (mainGaugeList.some(mg => mg.serialNumber == connectedGauge.serialNumber)) {
                gaugeList = mainGaugeList.map(g => {
                    return g.serialNumber != connectedGauge.serialNumber ? g :mapConnectedProperties(g, connectedGauge, deviceAgentVersion, deviceAgentConnectionId, properties?.passwordEnabled)
                })
            }
            else
            {
                gaugeList = mainGaugeList.concat([
                    convertGaugeToCorrectType({
                        ...connectedGauge,connected: true, runTags: connectedGauge.runTags ?? '',
                    })
                ])
            }
            AnnounceConnected(connectedGauge);
            return gaugeList
        })
    }
    const deviceDisconnected = (serialNumber: string) => {
        setMainGauges(mg => {
            return mg.map(g => g.serialNumber != serialNumber ? g : setGaugeDisconnected(g))
        })
    }
    const reportConnected = (connectedDevices: ConnectedDevice[], deviceAgentVersion: string, connectionId: string) => {
        console.log("report connected")
        connectedDevices.map(cd => {
            deviceConnected(cd.Gauge, cd.Logs, cd.Config, deviceAgentVersion, connectionId, cd.Properties)
        })
    }
    const clientDisconnected = (connectionId: string) => {
        setMainGauges(mg => {
            return mg.map(gauge => {
                return gauge.deviceAgentConnectionId == connectionId ? setGaugeDisconnected(gauge) : gauge
            })
        })
    }
    const reportEnableDataLogging = (success: boolean, serialNumber: string, errorMessage: string) => {
        if (success) {
            setMainGauges(mg => mg.map(gauge => {
                return gauge.serialNumber != serialNumber ? gauge : {...gauge, dataLogEnabled: true}
            }))
            messageQueue.sendSuccess(localeStore.Strings.dataLoggingUpgradeComplete)
        } else {
            messageQueue.sendError(localeStore.Strings.errorEnablingDevice);
        }
    }
    const reportFirmwareUpgradeComplete = (serialNumber: string, updatedGauge: GaugeBase, success: boolean, errorMessage: string) => {
        if (!success) {
            messageQueue.sendError(localeStore.Strings.errorUpdatingFirmware)
            return
        }
        setMainGauges(mg =>
            mg.map(gauge => {
                return gauge.serialNumber != serialNumber ? gauge : {
                    ...gauge,
                    firmwareVersion: updatedGauge.firmwareVersion
                }
            })
        )
        messageQueue.sendSuccess(localeStore.Strings.successUpgradingFirmware)
    }
    const reportConfigUpdateComplete = (serialNumber: string, success: boolean, errorMessage: string) => {
        if (success) {
            messageQueue.sendSuccess(localeStore.Strings.successUpdatingConfiguration)
        } else {
            console.error(errorMessage)
            setConfigs({...configs, [serialNumber]: Object.assign({},configs[serialNumber])  })
            if (errorMessage.includes("Password is incorrect"))
                messageQueue.sendError(localeStore.Strings.errorUpdatingConfigurationPasswordIncorrect)
            else
                messageQueue.sendError(localeStore.Strings.errorUpdatingConfiguration)
        }
    }
    const onNewConfigAvailable = (serialNumber: string, configuration: ConfigBase) => {
        setConfigs({...configs, [serialNumber]: configuration})
        setMainGauges(mg => mg.map(g => g.serialNumber != serialNumber? g : 
        {...g, name: configuration.name, calibrationDue: configuration.calibration.calibrationDue, calibrationDate: configuration.calibration.calibrationDate}
        ))
    }
    const onPasswordUpdate = (success: boolean, serialNumber: string, currentState: boolean) => {
        if (!success)
        {
            if (currentState) {
                messageQueue.sendError(translate.getString("passwordClearFailed"))
            }
            else
            {
                messageQueue.sendError(translate.getString("passwordSetFailed"))
            }
        }
        else
        {
            messageQueue.sendSuccess(translate.getString("gaugePasswordUpdated"))
            
            setMainGauges(mg => mg.map(mg => {
                if (mg.serialNumber != serialNumber)
                    return mg
                return {...mg, locked: currentState}
            }))
        }
            
    }
    const firmwareUpdateAvailable = (model: string | undefined, firmware: string | undefined) => {
        if (firmware == translate.getString("firmwareUpgradeInProgress"))
            return false
        if (firmware == undefined || model == undefined)
            return false
        let versionHint = firmware.split(".") 
        let current = {Major: +versionHint[0], Minor: +versionHint[1], Patch: +versionHint[2]}

        let newest = updatesContext.latestUpdate(model, {Major: 0, Minor: 0, Patch: 0, Build: 0} )
        if (!newest)
            return false
        
        
        return !(current.Major == newest.majorVersion && current.Minor == newest.minorVersion && newest.patchVersion == current.Patch)
    }
    const onSigRListening = () => {
        if (connectedToHub)
            return
        if (deviceHubConnection.connection == null)
            return;
        connectedToHub = true
        deviceHubConnection.connection.invoke("RequestAllConnected")
            .catch((e) => console.log("Unable to check all requested", e))
            .then(() => {
                console.log("Connected devices requested")
            })
        
    }
    const onSigRConnectionBroken = () => {
        connectedToHub = false;
        disconnectAll()
    }
    const connectToSignals = () =>
    {
        deviceHubConnection.connection?.on('DeviceConnected', deviceConnected)
        deviceHubConnection.connection?.on('DeviceDisconnected', deviceDisconnected)
        deviceHubConnection.connection?.on('ReportAllConnected', reportConnected)
        deviceHubConnection.connection?.on('ClientDisconnected', clientDisconnected)
        deviceHubConnection.connection?.on('ReportEnableDataLogging', reportEnableDataLogging)
        deviceHubConnection.connection?.on('ReportFirmwareUpgradeComplete', reportFirmwareUpgradeComplete)
        deviceHubConnection.connection?.on('ReportConfigUpdateComplete', reportConfigUpdateComplete)
        deviceHubConnection.connection?.on("NewConfigAvailable", onNewConfigAvailable)
        deviceHubConnection.connection?.on("passwordUpdated", onPasswordUpdate)
    }
    const breakSignalConnections = () => {
        connectedToHub = false;
        deviceHubConnection.connection?.off('DeviceConnected', deviceConnected)
        deviceHubConnection.connection?.off('DeviceDisconnected', deviceDisconnected)
        deviceHubConnection.connection?.off('ReportAllConnected', reportConnected)
        deviceHubConnection.connection?.off('ClientDisconnected', clientDisconnected)
        deviceHubConnection.connection?.off('ReportEnableDataLogging', reportEnableDataLogging)
        deviceHubConnection.connection?.off('ReportFirmwareUpgradeComplete', reportFirmwareUpgradeComplete)
        deviceHubConnection.connection?.off('ReportConfigUpdateComplete', reportConfigUpdateComplete)
        deviceHubConnection.connection?.off("NewConfigAvailable", onNewConfigAvailable)
        deviceHubConnection.connection?.off("passwordUpdated", onPasswordUpdate)
    }

    return (
        <GaugesContext.Provider value={{
            gaugesLoaded,
            gauges: getGauges(),
            demoMode: inDemoMode,
            getGauge,
            getConfig,
            getPermissions,
            setDemoMode: switchDemoMode,
            updateGauge,
            setConfig,
            firmwareUpdateAvailable,
        }}>
            {props.children}
        </GaugesContext.Provider>
    )
}