import {useContext, useEffect, useState} from 'react';
import {Button, DropdownProps, Form, Header, Icon, Menu, Modal} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {Language} from "@ametektci/ametek.stcappscommon/src/language/TextResources";
import {UserContext} from "../contexts/UserContext";
import {units} from "../utils/UnitDisplay";
import {Unit} from "../utils/PressureValue";

const LanguageOptions = [
    {flag : 'us', key : 'english', value : 'english', text : "English"},
    //{flag : 'fr', key : 'french', value : 'french', text : "Français"},
    //{flag : 'de', key : 'german', value : 'german', text : "Deutsche"},
    //{flag : 'es', key : 'spanish', value : 'spanish', text : "Español"},
    //{flag : 'cn', key : 'chinese', value : 'chinese', text : "中文"}
];

const DecimalSeparatorOptions = [
    { key: 'us', value: 'us', text: '1,234,567.89' },
    { key: 'si', value: 'si', text: '1 234 567,89' },
];
export default function LanguageSelection() {
    const userContext = useContext(UserContext)
    const [language, setLanguage] = useState( localeStore.getCurrentLanguage())
    const [decimalSeparator, setDecimalSeparator] = useState( userContext.decimalSeparator )
    const [visible, setVisible] = useState(false)
    const [workUnit, setWorkUnit] = useState<Unit>(userContext.pressureUnit as Unit)
    const [graphUnit, setGraphUnit] = useState<Unit | "null">((userContext.graphUnit as Unit | null??"null"))
    useEffect(() => {
        setWorkUnit(userContext.pressureUnit as Unit)
        setGraphUnit((userContext.graphUnit as Unit | null??"null"))
    }, [visible])
    //Remove when we're ready to support more languages
    useEffect(() => {
        if (language != "english")
        {
            userContext.setLanguage("english")
            setLanguage("english")
        }
    }, [language])
    const open = () => {
        setVisible(true)
        setDecimalSeparator(userContext.decimalSeparator)
        setLanguage(localeStore.language)
    }

    const close = () => {
        setVisible(false)
    };
    const changeLanguage = (e: any, {value}: DropdownProps) => {
        // do nothing if we didn't select a new option
        if (value === language) return;
        userContext.setLanguage(value as Language);
    };

    const saveSettings = () => {
        //UpdateUserLocaleSettings could use a bit of a tweak so we don't need to pass in undefined like this.
        userContext.updateUserLocaleSettings(workUnit.toLowerCase() as Unit,undefined, decimalSeparator);
        if (graphUnit == "null")
            userContext.setGraphUnit(null)
        else
            userContext.setGraphUnit(graphUnit)
        close();
    };

    return (
        <Modal size='small' open={visible}
               trigger={<Menu.Item onClick={open}><Icon name='setting' size='large' /> </Menu.Item>}>
            <Modal.Header content={localeStore.Strings.changeLocaleSettings}/>
            <Modal.Content>
                <Header size='small' content={localeStore.Strings.language} dividing/>
                <Icon name='language' color='blue' size='large'/>
                {'\xa0\xa0'}
                <Form.Dropdown selection name='language' onChange={changeLanguage}
                          options={LanguageOptions} value={language}/>

                <Header size='small' content={localeStore.Strings.decimalSeparator} dividing subheader={localeStore.Strings.decimalSeparatorSubheader}/>
                <Icon name='globe' color='blue' size='large'/>
                {'\xa0\xa0'}
                <Form.Dropdown selection name='decimalSeparator' onChange={(e, {value}) => setDecimalSeparator(value as 'us'|'si')}
                          options={DecimalSeparatorOptions} value={decimalSeparator}/>
                <Header size={"small"} content={localeStore.Strings.units} dividing />
                <Form>
                    <Form.Group>
                    <Form.Dropdown
                        options={units.filter(u => u.unit != "USER").map(u => ({value: u.unit.toLowerCase(), text: u.display}))}
                        value={workUnit}
                        label={localeStore.Strings.displayUnit} selection
                        onChange={(e, {value}) => setWorkUnit(value as Unit)}
                        />
                    <Form.Dropdown
                        options={units.concat([{unit: "null", display:localeStore.Strings.displayUnit}]).map(u => ({value: u.unit.toLowerCase(), text: u.display}))}
                        value={graphUnit}
                        label={localeStore.Strings.graphUnit} selection
                        onChange={(e, {value}) => setGraphUnit(value as Unit)}
                    />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} content={localeStore.Strings.cancel} />
                <Button color='blue' onClick={saveSettings} content={localeStore.Strings.confirm} />
            </Modal.Actions>
        </Modal>
    )
}
