import {Container, Grid} from "semantic-ui-react";
import Sidebar from "../sidebar/Sidebar";
import {GaugesContextWraper} from "../contexts/GaugesContext";
import {Outlet} from "react-router-dom";
import Header from "./Header";
import {UpdatesContextWrapper} from "../contexts/UpdatesContext";
import {LogsContextWrapper} from "../contexts/LogsContext";

export function CrystalControlWebLayout() {
    return <>
        <Header/>
        <Container>
            <Grid stackable>
                <Grid.Column width={4}>
                    <Sidebar/>
                </Grid.Column>
                <Grid.Column width={12}>
                    <Container>
                        <Outlet/>
                    </Container>
                </Grid.Column>
            </Grid>
        </Container>
    </>;
}

export function CrystalControlWeb() {
    return (
        <UpdatesContextWrapper>
            <GaugesContextWraper>
                <LogsContextWrapper>
                    <CrystalControlWebLayout />
                </LogsContextWrapper>
            </GaugesContextWraper>
        </UpdatesContextWrapper>
    )
}