import {useContext, useEffect, useState} from 'react';
import {Button, Divider, Message, Modal, Header,} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {CardSelection} from '@ametektci/ametek.stcappscommon';
import {GaugeBase} from "../Models/GaugeBase";
import {CreditCardInfo} from "@ametektci/ametek.stcappscommon/";
import {BillingPlan} from "@ametektci/ametek.stcappscommon";
import {UserContext} from "../contexts/UserContext";
import {BlueSnapShopper, DefaultShopper} from "../Models/BlueSnapShopper";
import {APIContext} from "../contexts/APIContext";
import {Country, State} from "../Models/LocationModels";
import {ConfirmPurchase} from "../blueSnap/ConfirmPurchase";
import {ManageShopper} from "../blueSnap/updateShopperInfo/ManageShopper";
import {LanguageContext} from "../contexts/LanguageContext";
export interface EnableLoggingOnDeviceProps {
    gauge: GaugeBase
}
export default function EnableLoggingOnDevice(props: EnableLoggingOnDeviceProps) {
    const api = useContext(APIContext)
    const translate = useContext(LanguageContext)
    const userContext = useContext(UserContext)
    const [visible, setVisible] = useState(false)
    const [paymentCard, setPaymentCard] = useState<CreditCardInfo>()
    const [paymentPlan,setPaymentPlan] = useState<BillingPlan>()
    const [shopper,setShopper] = useState<BlueSnapShopper>(DefaultShopper)
    const [countries, setCountries] = useState<Array<Country>>([])
    const [states, setStates] = useState<Array<State>>([])
    const[billingFormVisible,setShowBillingForm] = useState(false)
    const loadShopper = async () => {
        api.GetShopper().then(s => {
            console.log("fetched shopper",s)
            if(s == undefined)
                setShowBillingForm(true)
            else {
                if (s.shippingContactInfo === undefined)
                    s.shippingContactInfo = {address1: "", address2: "", city: "", state: "", zip: "", country: ""}
                setShopper(s)
                setShowBillingForm(s.address === undefined || s.shippingContactInfo.address1 === undefined)
            }
        })
    }
    useEffect(() => {
        api.FetchPlan("Datalogging").then(p => {
            let options = p.tiers.find(t => t.name.toUpperCase() == props.gauge.model.shortName.toUpperCase())
            setPaymentPlan(options?.billingPlans.find(bp => bp.length == "ONCE"))
        })
        loadShopper()
        api.getCountries().then(c => setCountries(c))
        api.getStates().then(s => setStates(s))
    },[])
    const open  = () => setVisible(true);
    const close = () => setVisible(false);

    const onCardSelection = (paymentCard?: CreditCardInfo) => setPaymentCard(paymentCard);

    const purchaseUpgrade = () => {
                setVisible(false)
    };

    const hideBillingForm = (show:boolean) => {
        setShowBillingForm(show)
    }
    const updateShopper = async (shopper : BlueSnapShopper) =>{
        await api.UpdateShopper(shopper)
        await loadShopper()
    }


    //If there is no upgrade to purchase, don't begin rendering the modal. We can't use it yet.
    if (paymentPlan == null)
        return (
            <span>
                <Button icon='unlock' fluid onClick={open} disabled={true}
                        content={ localeStore.Strings.loading } />
            </span>
        )
    
        return (
            <span>
                <Divider />

                <Message header={localeStore.Strings.deviceNotEnabled} content={localeStore.Strings.purchaseUpgradeToEnable}/>

                {!userContext.user!.isAdmin ?
                    <Message size='tiny' warning header={localeStore.Strings.contactAdmin} content={localeStore.Strings.onlyAdminsCanUpgrade} />
                    : null
                }

                <Modal size='small' open={visible}
                       trigger={
                           <Button icon='unlock' fluid onClick={open}
                                   color={ 'green' }
                                   content={  localeStore.Strings.purchaseUpgrade } />
                       }>
                    <Modal.Header>{localeStore.Strings.activateDevice}</Modal.Header>
                    <Modal.Content>

                        {userContext.user!.isAdmin ?
                            <span>
                                <Message header={localeStore.Strings.oneTimeActivation} content={localeStore.Strings.oneTimeActivationDescription} />

                                <Header size='small' color='blue' content={localeStore.Strings.paymentMethod} dividing/>
                                <CardSelection onCardSelection={onCardSelection} selectedCard={paymentCard} />

                                <Divider />
                                <ManageShopper 
                                        shopper={shopper} 
                                        updateShopper={updateShopper} 
                                        countries={countries} states={states} 
                                        translate={translate.getString}
                                        summaryOnly={!billingFormVisible}
                                        isOpen={hideBillingForm}
                                        />
                                <ConfirmPurchase 
                                    onPurchaseUpgrade={purchaseUpgrade}
                                    model={props.gauge.model.shortName} 
                                    visible={!billingFormVisible} 
                                    card={paymentCard!}
                                    plan={paymentPlan!}
                                />
                            </span>
                            :
                            <Message warning header={localeStore.Strings.contactAdmin} content={localeStore.Strings.onlyAdminsCanUpgrade} />
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={close} content={localeStore.Strings.cancel} />
                    </Modal.Actions>
                </Modal>

            </span>
        )
}
