import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {ConfigBase} from "../Models/Config/ConfigBase";
import axios, {AxiosHeaders, AxiosResponse, HttpStatusCode, InternalAxiosRequestConfig} from "axios";
import {
    authenticationStore,
    BillingPlan,
    CreditCardInfo,
    Log,
    Product,
    SetEnvironment
} from "@ametektci/ametek.stcappscommon";
import {ExportEnvironmentSettings, GetEnvironmentSetting} from "../utils/EnvironmentSettings";
import {LanguageContext} from "./LanguageContext";
import localeStore from "../language/LocaleStore";
import {Loader} from "semantic-ui-react";
import {BlueSnapShopper} from "../Models/BlueSnapShopper";
import {Country, State} from "../Models/LocationModels";
import {TaxQuoteResponse} from "../Models/Requests/TaxQuote";
import {AddressInfo} from "@ametektci/ametek.stcappscommon/src/DataStructures/AddressInfo";

const fakeAxiosResponse : AxiosResponse = {
    status: 404,
    statusText: "no",
    config: {
        headers: {} as unknown as AxiosHeaders, //I need this for typing reasons but should never use it.
    },
    data: {},
    headers: {}
}
const loadingPlan = {name: "Loading", tiers: [{billingPlans: [], name:"Loading", features: [], nameString: localeStore.Strings.loading}]} as Product
export const APIContext = createContext({
    getGaugeConfig: (sn: string) => Promise.resolve(null as ConfigBase | null),
    getGaugePermissions: (sn: string) => Promise.resolve(fakeAxiosResponse as AxiosResponse<any>),
    joinOrg: (code: string) => Promise.resolve([false, "NO_PROVIDER"] as [boolean, string]),
    CreateOrg: (name: string) => Promise.resolve([false, "NO_PROVIDER"] as [boolean, string]),
    GetFileURL: (fileName: string,serialNumber: string, demo: boolean) => Promise.resolve(["",0] as [string, number]),
    DownloadExcel: (logid: number) => Promise.resolve(""),
    GetLogs: (sn: string, archived: boolean) => Promise.resolve([] as Array<Log>),
    GetRecentLogs: (count: number, days: number) => Promise.resolve([] as Array<Log>),
    UploadLog: async (file: File, newLog: Log, serial:string) => Promise.resolve(),
    LoadCertificates: () => Promise.resolve(fakeAxiosResponse),
    EnableDataLogger : (paymentCard: CreditCardInfo, paymentPlan: BillingPlan, serial : string, shortName: string) => Promise.resolve(fakeAxiosResponse),
    GetDataLoggerQuote: (model: string) => Promise.resolve({tax: 0, price: 0, priceWithTax: 0} as TaxQuoteResponse),
    FetchPlan: (product: string) => Promise.resolve(loadingPlan),
    GetShopper: () => Promise.resolve(null as BlueSnapShopper | null),
    UpdateShopper: (updatedShopper: BlueSnapShopper) => Promise.resolve(),
    getCountries: () => Promise.resolve([] as Array<Country>),
    getStates: () => Promise.resolve([] as Array<State>),
    VerifyAddress: (addr: AddressInfo) => Promise.resolve([] as Array<AddressInfo>),
    ccwVersion: "",
    managementConsoleVersion: "",
    documentationUrl: "",
    managementConsoleUrl:"",
    managementConsoleApi:"",
    updatesUrl:"",
})

let loadedProducts: { [key: string]: Product } = {}

export function APIContextWrapper (props: PropsWithChildren) {
    const translate = useContext(LanguageContext)
    const [documentationUrl ,setDocumentationUrl] = useState<string>("")
    const [managementConsoleApi,setManagementConsoleApi] = useState<string>("")
    const [managementConsoleClient ,setManagementConsoleClient] = useState<string>("")
    const [updatesUrl, setUpdatesUrl] = useState<string>("")
    const ccwAxios = axios.create({
        baseURL: GetEnvironmentSetting("CcwApiUrl"),
    })
    const [ccwVersion, setCCWVersion] = useState("")
    const managementAxios = axios.create({
        baseURL: managementConsoleApi,
    })
    const [managementConsoleVersion, setManagementConsoleVersion] = useState("")
    const generateAuthHeader = async (request: InternalAxiosRequestConfig) => {
        request.headers.Authorization = "bearer " + await authenticationStore.getAccessToken()
        return request
    }
    ccwAxios.interceptors.request.use(generateAuthHeader)
    managementAxios.interceptors.request.use(generateAuthHeader)
    useEffect(() => {
        getCrystalControlEnvironment()
    },[])
    useEffect(() => {
        if (managementConsoleApi != "")
            getManagementConsoleEnvironment()
    },[managementConsoleApi])
    const getCrystalControlEnvironment = async () => {
        var env = await ccwAxios.get("/Environment")
        setCCWVersion(env.data.version ?? translate.getString("unknown"))
        setDocumentationUrl(env.data.documentationUrl)
        setManagementConsoleApi(env.data.managementConsoleApi)
        setManagementConsoleClient(env.data.managementConsoleUrl)
        setUpdatesUrl(env.data.updatesUrl)
        let oldEnv = ExportEnvironmentSettings()
        SetEnvironment({...oldEnv,managementConsoleApi:env.data.managementConsoleApi })
    }
    const getManagementConsoleEnvironment = async () => {
        var env = await managementAxios.get("/Environment")
        setManagementConsoleVersion(env.data.version ?? translate.getString("unknown"))
    }
    const getGaugeConfig = async (sn: string) => {
        let response = await ccwAxios.post("/GaugeConfig", {
            serialNumber: sn
        }, {validateStatus: () => true})
        return response.data.gaugeConfig ?? null
    }
    const getGaugePermissions = async (sn: string) => {
        return await managementAxios.post(`/gauge/permissions/`, {
            SerialNumber: sn,
        })
    }
    const joinOrg = async (code: string) => {
        let joined = await managementAxios.post("/JoinOrganization", {
            JoinOrganizationCode : code
        }, {validateStatus: () => true})
        console.log(joined)
        return [(joined.status == HttpStatusCode.Ok || joined.status == HttpStatusCode.NoContent), joined.data.responseStatus?.message ?? ""] as [boolean, string]
    }
    const CreateOrg = async (code: string) => {
        let joined = await managementAxios.post("/CreateOrganization", {
            OrganizationName : code
        },{validateStatus: () => true})
        return [(joined.status == HttpStatusCode.Ok || joined.status == HttpStatusCode.NoContent), joined.data.responseStatus?.message ?? ""] as [boolean, string]
    }
    const GetFileURL = async (fileName: string,serialNumber: string, demo: boolean) : Promise<[string, number]> => {
        let response = await ccwAxios.post("/Log", {
            LogFilename: fileName,
            SerialNumber: serialNumber,
            IsDemoData: demo,
        })
        return [response.data.preSignedFileUrl, response.data.fileSize]
    }
    const DownloadExcel = async (logId: number) => {
        return (await ccwAxios.post("/Log/Export/Excel", {logId: logId})).data
    }
    const GetLogs = async (serialNumber: string, archived: boolean) : Promise<Array<Log>> =>
    {
        return (await ccwAxios.post("/Logs", {serialNumber: serialNumber, archived: archived})).data
    }
    const GetRecentLogs = async (count: number, days: number)     : Promise<Array<Log>> => {
        return (await ccwAxios.post("/RecentLogs", {count:count, maxDays: days})).data
    }
    const UploadLog = async (file: File, log:Log, serial: string) : Promise<void> => {
        let uploadURL = (await ccwAxios.post("/Log/Upload", {
            serialNumber: serial,
            log
        })).data.url
        await axios.put(uploadURL, file, {headers: {
            "Content-Type": "text/csv",
            }} )
    }
    const LoadCertificates = async () => {
        return await ccwAxios.get("/Certificates")
    }
    const EnableDataLogger = async (paymentCard: CreditCardInfo, paymentPlan: BillingPlan, serial : string, shortName: string) => {
        return await ccwAxios.post("/EnableDataLogger",
        {
            DeviceSerialNumber : serial,
            Series: shortName,
                CardLastFourDigits: paymentCard.creditCard.cardLastFourDigits,
                CardType: paymentCard.creditCard.cardType,
                PlanId: paymentPlan.planId,
                PurchaseDescription: paymentPlan.description,
        }, {validateStatus: () => true})
    }
    const GetDataLoggerQuote = async (model: string)=>
    {
        return (await managementAxios.post<TaxQuoteResponse>("/Billing/GetPrice", {model: model})).data
    }
    
    const GetPlansFromDatabase = async (product: string) => {
        try {
            let response = await managementAxios.get<{subscribable: Product}>(`/Plans/${product}`, {validateStatus: () => true})
            if (response.status == 200)
            {
                response.data.subscribable.tiers.forEach((t) => {
                    t.nameString = localeStore.getTextDynamic(t.name)
                    t.features.forEach((f) => {
                        f.name = localeStore.getTextDynamic(f.name)
                    })
                    t.billingPlans.forEach((bp) => {
                        bp.description = localeStore.getTextDynamic(bp.description)
                        bp.priceString = localeStore.Strings.priceString.replace("###", bp.price.toString())
                        bp.intervalString = localeStore.getTextDynamic("PER_"+bp.length)
                    })
                })
                loadedProducts[product] = response.data.subscribable
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    const FetchPlan = async (product: string): Promise<Product> => {
        if (!loadedProducts[product])
            await GetPlansFromDatabase(product)
        return loadedProducts[product] ?? loadingPlan

    }
    const GetShopper = async () => {
        let response = await axios.get(managementConsoleApi+'/Shopper',{headers:{
                "Authorization":"bearer " + await authenticationStore.getAccessToken()
            }})

        if(response.status == 200)
            return response.data.shopper
    }

    //update shopper after purchase then send email
    const UpdateShopper = async (updatedShopper: BlueSnapShopper)=> {
        await axios.put(managementConsoleApi + '/Shopper', {
            shopper: updatedShopper
        },{
            headers: {
                "Authorization": "bearer " + await authenticationStore.getAccessToken()
            }
        })
    }
    const getCountries = async () => {
        return (await managementAxios.get("/Countries")).data
    }
    const getStates = async () => {
        return (await managementAxios.get("/States")).data
    }
    const VerifyAddress = async (addr: AddressInfo) => {
        let response = await managementAxios.post<{
            reccommendedAddreses: AddressInfo[]
        }>("/Billing/ValidateAddress", {address: addr})
        if (response.status == HttpStatusCode.Ok)
            return response.data.reccommendedAddreses ?? []
        else throw response
    }
    if (managementConsoleApi == "")
    return (
        <Loader content={translate.getString("connectingToCrystalControlWeb")} style={{height:"100%",centered: true}} size={"large"} active/>
    )
    return (
        <APIContext.Provider value={{
            GetFileURL,
             getGaugeConfig,
            getGaugePermissions,
            joinOrg,
            CreateOrg,
            DownloadExcel,
            GetLogs,
            GetRecentLogs,
            UploadLog,
            LoadCertificates,
            EnableDataLogger,
            GetDataLoggerQuote,
            FetchPlan,
            GetShopper,
            UpdateShopper,
            getCountries,
            getStates,
            VerifyAddress,
            ccwVersion,
            managementConsoleVersion,
            managementConsoleApi,
            managementConsoleUrl: managementConsoleClient,
            updatesUrl,
            documentationUrl,
            }}>
            {props.children}
        </APIContext.Provider>
    )
}