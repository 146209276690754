import {AddressInfo} from "@ametektci/ametek.stcappscommon/src/DataStructures/AddressInfo";
import {Button, Form, Grid, Header, Modal} from "semantic-ui-react";
import {AddressDisplay} from "./AddressDisplay";
import {useContext, useState} from "react";
import {Country} from "../../Models/LocationModels";
import {ShopperContext} from "./shopperContext";

interface AddressSelectionProps {
    addresses: Array<AddressInfo>
    asEntered: AddressInfo
    onSelected: (addr: AddressInfo) => void
    onCancel: () => void
    show: boolean
    countries: Array<Country>
}
export function AddressSelection (props: AddressSelectionProps) {
    const shopperContext = useContext(ShopperContext)
    const [selected, setSelected] = useState<number>()
    const onAccept = () =>
    {
        if (selected == undefined)
            return
        if (selected == -1)
            props.onSelected(props.asEntered)
        props.onSelected(props.addresses[selected])
    }
    return (
        <Modal open={props.show} onClose={() => props.onCancel}>
            <Modal.Header>{shopperContext.translate("selectAddress")}</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header size={"small"}>{shopperContext.translate("asFound")}</Header>
                        </Grid.Column>
                    </Grid.Row>
                    {props.addresses.map((addr,idx) =>
                        (
                            <Grid.Row key={idx}>
                                <Grid.Column width={1}>
                                    <Form.Radio
                                        checked={selected == idx}
                                        onChange={() => setSelected(idx)}
                                    />
                                </Grid.Column>
                                <Grid.Column width={15}>
                                    <AddressDisplay addr={addr}/>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    )}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header size={"small"}>{shopperContext.translate("asEntered")}</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={1}>
                        <Form.Radio
                            checked={selected == -1}
                            onChange={() => setSelected(-1)}
                        />
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <AddressDisplay addr={props.asEntered}/>
                    </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    onClick={() => props.onCancel()}
                    color={"red"}
                >{shopperContext.translate("cancel")}</Button>
                <Button 
                    onClick={onAccept}
                    color={"blue"}
                    disabled={selected == undefined}
                >
                    {shopperContext.translate("select")}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}