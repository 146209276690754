import {
    Header,
    Button,
    Container,
    Menu,
    Image,
    Grid,
    Transition,
    Segment,
    Card,
    Divider,
    List
} from 'semantic-ui-react';
import {Images, updatesStore, Products, AmetekAuthentication} from '@ametektci/ametek.stcappscommon';
import localeStore from "../language/LocaleStore";
import graphLines from "../images/Feature Preview/graphingLinesBackground.png";
import featureDeviceConfiguration from "../images/Feature Preview/featureDeviceConfiguration.PNG";
import featureGraphing from "../images/Feature Preview/featureGraphing.PNG";
import featureLogManagement from "../images/Feature Preview/featureLogManagement.PNG";
import featurePdf from "../images/Feature Preview/featurePdf.PNG";
import featureOrganizationManagement from '../images/Feature Preview/featureManagementConsole.png';
//import featureOffline from '../images/Feature Preview/featureOffline.png';
import {useContext, useEffect, useState} from "react";
import CookieConsent from "../Cookies/CookieConsent";
import {APIContext} from "../contexts/APIContext";

export default function SplashPage(){
    const api = useContext(APIContext)
    const [getStartedBounceTrigger, setGetStartedBounceTrigger] = useState(true)
    useEffect( () => {
        setInterval(() => setGetStartedBounceTrigger(!getStartedBounceTrigger), 5000)
        CookieConsent()
    }, [])

        return (
            <div>
                <Menu attached={"top"} inverted borderless size='huge' >
                    <Container>
                        <Menu.Item>
                            <Image src={Images.ccwLogoWhite} size='large' />
                        </Menu.Item>

                        <Menu.Menu position='right'>
                            <Menu.Item>
                                <AmetekAuthentication page={"signIn"}>
                                    <Button content={localeStore.Strings.signIn} inverted basic/>
                                </AmetekAuthentication>
                            </Menu.Item>

                            <Menu.Item>
                                <AmetekAuthentication page={"signUp"} >
                                    <Button color='green' content={localeStore.Strings.signUp}/>
                                </AmetekAuthentication>
                            </Menu.Item>
                        </Menu.Menu>
                    </Container>
                </Menu>

                <div style={{paddingTop : '6em'}}>

                    <div className='splashFirstBox' style={{backgroundImage: `url(${graphLines})`}}>

                        <Grid>
                            <Grid.Column largeScreen={2} tablet={1} mobile={1}  />
                            <Grid.Column largeScreen={8} computer={12} tablet={14} mobile={14}  >
                                <Segment size='huge' style={{marginTop : '75px'}}>
                                    <Image size='huge' src={Images.ccwLogoBlack} />

                                    <Divider hidden/>

                                    <Container>
                                        {localeStore.Strings.applicationDescription}
                                    </Container>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column largeScreen={6} tablet={1} mobile={1} />
                        </Grid>

                        <Transition animation='pulse' duration={1000} visible={getStartedBounceTrigger}>
                            <Container textAlign='center' className='getStartedButtonContainer'>
                                <AmetekAuthentication page={"signUp"} >
                                    <Button size='massive' color='green' content={localeStore.Strings.getStarted} />
                                </AmetekAuthentication>
                            </Container>
                        </Transition>

                    </div>

                    <div className='splashSecondBox'>
                        <Container>
                            <Header inverted content={localeStore.Strings.features} size='huge'/>

                            <Card.Group itemsPerRow={3} stackable>

                                <Card>
                                    <Image src={featureGraphing} wrapped ui={false} />
                                    <Card.Content header={localeStore.Strings.graphing}
                                                  description={localeStore.Strings.graphingDescription}/>
                                </Card>

                                <Card>
                                    <Image src={featureLogManagement} wrapped ui={false} />
                                    <Card.Content header={localeStore.Strings.logManagement}
                                                  description={localeStore.Strings.logManagementDescription}/>
                                </Card>

                                <Card>
                                    <Image src={featureOrganizationManagement} wrapped ui={false} />
                                    <Card.Content header={localeStore.Strings.organizationManagement}
                                                  description={localeStore.Strings.organizationManagementDescription}/>
                                </Card>

                                <Card>
                                    <Image src={featurePdf} wrapped ui={false} />
                                    <Card.Content header={localeStore.Strings.pdfReporting}
                                                  description={localeStore.Strings.pdfReportingDescription}/>
                                </Card>

                                <Card>
                                    <Image src={featureDeviceConfiguration} wrapped ui={false} />
                                    <Card.Content header={localeStore.Strings.deviceConfiguration}
                                                  description={localeStore.Strings.deviceConfigurationDescription}/>
                                </Card>
                                {/* Offline client is not yet ready. Hiding the feature preview until it is.
                                <Card>
                                    <Image src={featureOffline} wrapped ui={false} />
                                    <Card.Content header={localeStore.Strings.offlineAppName}
                                                  description={localeStore.Strings.offlineAppDescription}/>
                                </Card>
                                */}
                            </Card.Group>

                        </Container>
                    </div>
                </div>

                <Segment inverted vertical style={{ padding: '4em 0em' }}>
                    <Container>
                        <Grid divided inverted stackable>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Image src={Images.stcLogo} size='medium'/>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Header inverted as='h4' content={localeStore.Strings.applicationName} />
                                    <List link inverted>
                                        <List.Item as='a' content={localeStore.Strings.organizationManagement} href={api.managementConsoleUrl}/>
                                        {/*Offline client is not ready.*/}
                                        {/*<List.Item as='a' content={localeStore.Strings.offlineAppName} onClick={() => updatesStore.downloadUpdateFile(Products.OfflineClient)} />*/}
                                        <List.Item as='a' content={localeStore.Strings.deviceAgent} onClick={() => updatesStore.downloadUpdateFile(Products.DeviceAgent)}/>
                                        <List.Item as='a' content={localeStore.Strings.documentation} href={api.documentationUrl}/>
                                    </List>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Header inverted as='h4' content='AMETEK' />
                                    <List link inverted>
                                        <List.Item as='a' href='https://www.ametekcalibration.com/' content={localeStore.Strings.ametekCalibration}/>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

            </div>
        )
}
