import {useContext, useEffect} from 'react';
import {
    MessageFeed,
    authenticationStore,
    updatesStore,
    Products
} from '@ametektci/ametek.stcappscommon';
import {UserContext} from "./contexts/UserContext";
import {CrystalControlWeb, CrystalControlWebLayout} from "./global/CrystalControlWeb";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";
import WelcomePanel from "./mainpanel/WelcomePanel";
import {GaugePage} from "./mainpanel/gaugePage";
import {VerifySwitch} from "./JoinOrganization/VerifySwitch";
import {JoinOrg} from "./JoinOrganization/JoinOrg";
import {NewUser} from "./JoinOrganization/NewUser";

export default function App() {
    const userContext = useContext(UserContext)
    const isNewUser = userContext.accessLevel === 'newUser'
    //App login state

    useEffect(() => {
        // pre load device agent updates
        updatesStore.loadLatestUpdate(Products.DeviceAgent)
        authenticationStore.refreshState()
    }, [])
    const router = createBrowserRouter([
        {
            path: "/*",
            element: isNewUser ? <Navigate to={"/join"}/> : <CrystalControlWeb/>,
            children: [ //Included but not yet implemented. A 'Not Found' page will be needed in GaugePage. We also need an outlet.
                {
                    path: "*",
                    element: <WelcomePanel/>,
                },
                {
                    path: "gauge", //catches that one gauge with a serial of "". Should never make it to production.
                    element: <GaugePage/>
                },
                {
                    path: "gauge/:serial",
                    element: <GaugePage/>
                }
            ]
        },
        {
            path: "/join",
            element: <CrystalControlWebLayout/>,
            children: [
                {
                    path: "",
                    element: <div><VerifySwitch><NewUser/></VerifySwitch></div>
                },
                {
                    path: ":code",
                    element: <div><WelcomePanel /><VerifySwitch><JoinOrg/></VerifySwitch></div>
                }
            ]
        },
    ])


    return (
        <div className="App">
            <RouterProvider router={router}/>
            <MessageFeed/>
        </div>
    );
}
