import {Checkbox, Header, Input, Message, Segment, SegmentGroup} from "semantic-ui-react";
import {useContext, useState} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
import localeStore from "../../language/LocaleStore";
import {PressureSensorConfigBase} from "../../Models/Config/PressureSensorConfigBase";
import {LanguageContext} from "../../contexts/LanguageContext";
interface SpecialFeatureControlsProps {
    sensor: PressureSensorConfigBase
    psvEnabled?: boolean
    settingsChange(sensor: PressureSensorConfigBase, valid: boolean): void
    togglePSV: (newPSV: boolean) => void
}

export function SpecialFeatureControls(props: SpecialFeatureControlsProps) {
    const translate = useContext(LanguageContext)
    const gaugeContext = useContext(GaugeContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    const [averageRateWindowSizeError, setAverageRateWindowSizeError] = useState<string>("")
    const onAverageRateChange = (newAverageRate: string) => {
        let value = Number(newAverageRate);
        // enforce entering the value with at most one digit of precision
        let decimalPrecision = value.toString().indexOf('.') // todo : support , number format
        let precision = decimalPrecision === -1 ? 0 : value.toString().length - decimalPrecision - 1;

        let valid = 2 <= value && value <= 10 && precision <= 1;

        if (valid)
            setAverageRateWindowSizeError("")
        else
            setAverageRateWindowSizeError(localeStore.Strings.filterTimeValidation)

        props.settingsChange({...props.sensor, readingsToAverage: value}, valid);
        //average mode COULD go in here, but it's tied to peaks. That seems arbitrary, but it's required to avoid making too many changes to the UI layout.
    }
    const setRateEnabled = () => {
        props.settingsChange({...props.sensor, rateEnabled: !props.sensor.rateEnabled}, !averageRateWindowSizeError)
    }
    if (!gaugeContext.dualDisplay && props.psvEnabled == null)
        return null
    return (
        <SegmentGroup>
            {gaugeContext.dualDisplay? 
            <Segment>
                <Header>{translate.getString("rateModeHeader")}</Header>
                <Checkbox label={translate.getString("enableRateModeToggleLabel")} checked={props.sensor.rateEnabled} onChange={() => setRateEnabled()} disabled={disableControls} />
                
                <Header content={translate.getString("averageRateWindowSizeLabel")} dividing size={"small"} />
                <Input
                    fluid type='number'
                    error={averageRateWindowSizeError != ""}
                    onChange={(e, {value}) => onAverageRateChange(value)} 
                    value={props.sensor.readingsToAverage}
                    disabled={disableControls || !props.sensor.rateEnabled}
                    min={2}
                    max={10}
                    step={1}
                />
                <Message error content={averageRateWindowSizeError} hidden={averageRateWindowSizeError == ""}/>
            </Segment> 
                : null
            }
            {props.psvEnabled != null ?
            <Segment>
                <Header>{translate.getString("psvModeHeader")}</Header>
                <Checkbox
                    checked={props.psvEnabled}
                    onChange={() => props.togglePSV(!props.psvEnabled)}
                    disabled={disableControls}
                    label={translate.getString("enablePSVDescription")}
                />
            </Segment>
                : undefined}
        </SegmentGroup>
    )
}