import {GaugeBase} from "../GaugeBase";
import singleDisplay from '../../images/gauges/xp3i/XP3I_Standard.png';
import dualDisplay from '../../images/gauges/xp3i/XP3i-DualDisplay.png'
import {TemperatureUnit} from "../units";
import {Gauge} from "@ametektci/ametek.stcappscommon";

export class XP3I implements GaugeBase {
    constructor(item: Gauge | GaugeBase) {
        Object.assign(this, item)
        this.organizationId = item.organizationId
        this.name = item.name ?? ""
        this.connected = item.connected
        this.dataLogEnabled = item.dataLogEnabled
        this.connectedDeviceAgentVersion = item.connectedDeviceAgentVersion
        this.gaugeId = item.gaugeId
        this.model = item.model
        this.serialNumber = item.serialNumber
        this.calibrationDate = item.calibrationDate
        this.calibrationDue = item.calibrationDue
        this.deviceAgentConnectionId = item.deviceAgentConnectionId
        this.firmwareVersion = item.firmwareVersion
        this.lastSeen = item.lastSeen
        this.runTags = item.runTags
        this.modelId = item.modelId
        this.dataLogPurchased = item.dataLogPurchased
        
        return this
    }
    organizationId: number
    name: string
    connected: boolean;
    connectedDeviceAgentVersion: any;
    dataLogEnabled: boolean;
    gaugeId: number;
    serialNumber: string;
    model: { name: string, shortName: any, liveLoggingCapable: boolean };
    modelId: number
    singleSensor = true
    picture = () => {
        return this.model.name.includes("-DD") ? dualDisplay : singleDisplay
    }
    supportedTemps = () : Array<TemperatureUnit> => {
        return ["4C","60F","68F"]
    }
    peaksAndAveraging?: {
        peaks: boolean
        average: boolean
        averageSamples: number
    }
    calibrationDate: string;
    calibrationDue: string;
    deviceAgentConnectionId: any;
    firmwareVersion: any;
    lastSeen: any;
    runTags?: string;
    dataLogPurchased: boolean
}