import {Accordion, Button, Card, Form, Grid, Header, Icon, Popup} from "semantic-ui-react";
import {useContext, useEffect, useState} from "react";
import {messageQueue} from "@ametektci/ametek.stcappscommon";
import {AddressInfoForm} from "./AddressInfoForm";
import {AddressInfo} from "@ametektci/ametek.stcappscommon/src/DataStructures/AddressInfo";
import {ShopperContext} from "./shopperContext";
interface ShopperInfoProps {
    summaryOnly: boolean
    isOpen: (a: boolean) => void
}

export function ShopperInfo(props: ShopperInfoProps) {
    const shopperContext = useContext(ShopperContext)
    const [updating, setUpdating] = useState(false)
    const [email, setEmail] = useState(shopperContext.shopper.email)
    const [phone, setPhone] = useState(shopperContext.shopper.phone)
    const [company, setCompany] = useState(shopperContext.shopper.companyName)
    const [billingOpen, setBillingOpen] = useState(false)
    const [shippingOpen, setShippingOpen] = useState(false)
    useEffect(() => {
        props.isOpen(dirty|| billingOpen || shippingOpen || phone != shopperContext.shopper.phone || email != shopperContext.shopper.email )
    },[billingOpen, shippingOpen, phone, email, shopperContext.shopper])
    const [dirty, setDirty] = useState(false)
    const [billAddr, setBillAddr] = useState<AddressInfo>({
        address: shopperContext.shopper.address,
        address2: shopperContext.shopper.address2,
        city: shopperContext.shopper.city,
        country: shopperContext.shopper.country,
        state: shopperContext.shopper.state ?? "",
        zip: shopperContext.shopper.zip
    })
    const [shipAddr, setShippAddr] = useState<AddressInfo>({
        address: shopperContext.shopper.shippingContactInfo?.address1 ?? "",
        address2: shopperContext.shopper.shippingContactInfo?.address2 ?? "",
        city: shopperContext.shopper.shippingContactInfo?.city ?? "",
        country: shopperContext.shopper.shippingContactInfo?.country ?? "",
        state: shopperContext.shopper.shippingContactInfo?.state ?? "",
        zip: shopperContext.shopper.shippingContactInfo?.zip ?? ""
    })
    const [bilAddrIsShipAddr, setBillAddrIsShipAddr] = useState(false)
    const resetShopperInfo = () => {
        setEmail(shopperContext.shopper.email)
        setPhone(shopperContext.shopper.phone)
        setCompany(shopperContext.shopper.companyName)
        setBillAddr({
            address: shopperContext.shopper.address,
            address2: shopperContext.shopper.address2,
            city: shopperContext.shopper.city,
            country: shopperContext.shopper.country,
            state: shopperContext.shopper.state ?? "",
            zip: shopperContext.shopper.zip
        })
        setShippAddr({
            address: shopperContext.shopper.shippingContactInfo?.address1 ?? "",
            address2: shopperContext.shopper.shippingContactInfo?.address2 ?? "",
            city: shopperContext.shopper.shippingContactInfo?.city ?? "",
            country: shopperContext.shopper.shippingContactInfo?.country ?? "",
            state: shopperContext.shopper.shippingContactInfo?.state ?? "",
            zip: shopperContext.shopper.shippingContactInfo?.zip ?? ""
        })
        setDirty(false)
        setBillAddrIsShipAddr(false)
    }
    const updateShopperInfo = () => {
        setUpdating(true)
        shopperContext.updateShopper({
            ...shopperContext.shopper,
            email: email,
            phone: phone,
            companyName: company,
            country: billAddr.country,
            address: billAddr.address,
            address2: billAddr.address2,
            city: billAddr.city,
            state: billAddr.state,
            zip: billAddr.zip,
            shippingContactInfo: bilAddrIsShipAddr ? {
                country: billAddr.country,
                address1: billAddr.address,
                address2: billAddr.address2,
                city: billAddr.city,
                state: billAddr.state,
                zip: billAddr.zip
            } : {
                country: shipAddr.country,
                address1: shipAddr.address,
                address2: shipAddr.address2,
                city: shipAddr.city,
                state: shipAddr.state,
                zip: shipAddr.zip
            }
        })
            .then(() => {
                setUpdating(false)
                setDirty(false)
            })
            .catch(() => {
                messageQueue.sendError(shopperContext.translate("errorUpdatingShopper"))
                setUpdating(false)
            })
    }
    const updateBillingAddress = (addr: AddressInfo) => {
        setBillAddr(addr)
        console.log("updating billing addr")
        setDirty(true)
        setBillingOpen(false)
    }
    const updateShippingAddress = (addr: AddressInfo) => {
        setShippAddr(addr)
        console.log("updating shipping addr")
        setDirty(true)
        setShippingOpen(false)
    }
    const onSetEmail = (email: string) => {
        setEmail(email)
        console.log("setting email")
        setDirty(true)
    }
    const onSetPhone = (phone: string) => {
        setPhone(phone)
        console.log("setting phone")
        setDirty(true)
    }
    const onSetCompany = (company: string) => {
        setCompany(company)
        console.log("setting company")
        setDirty(true)
    }

    useEffect(() => {
        resetShopperInfo()
    }, [shopperContext.shopper])
    return (
        <>
            <Header size={'large'} dividing={true}>
                {shopperContext.translate('payerInfo')}
            </Header>
            <Card fluid>
                <Card.Content>
                    <Form>
                        <Header size={'medium'}>{shopperContext.translate("contactInfo")}</Header>
                        <Form.Group widths={"equal"}>
                            <Form.Input
                                label={shopperContext.translate("email")}
                                value={email}
                                onChange={(e, {value}) => onSetEmail(value)}
                            />
                            <Form.Input
                                label={shopperContext.translate("phone")}
                                value={phone}
                                onChange={(e, {value}) => onSetPhone(value)}
                                maxLength="36"
                            />
                        </Form.Group>
                        <Form.Input
                            label={shopperContext.translate("companyName")}
                            value={company}
                            onChange={(e, {value}) => onSetCompany(value)}
                            maxLength="100"
                        />
                        <Accordion>
                            <Accordion.Title onClick={() => setBillingOpen(true)}>
                                <Header size={'medium'}>
                                    {billingOpen? "" :
                                    <Icon name={"edit"} disabled={bilAddrIsShipAddr}/>
                                    }
                                    {shopperContext.translate("billingInformation")}
                                </Header>
                            </Accordion.Title>
                            <AddressInfoForm visible={billingOpen} address={billAddr}
                                             onSetAddress={updateBillingAddress}
                                             onCancel={() => setBillingOpen(false)}
                                hideAddr={false}
                            />
                        </Accordion>
                        <Accordion>
                            <Accordion.Title>
                                <Grid columns={"equal"}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header onClick={bilAddrIsShipAddr ? null : () => setShippingOpen(true)}
                                                    size={'medium'}>
                                                {shippingOpen? "" :
                                                    <Icon name={"edit"} disabled={bilAddrIsShipAddr}/>
                                                }
                                                {shopperContext.translate("shippingInformation")} &nbsp;
                                            </Header>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Checkbox label={shopperContext.translate("useBillingForShipping")}
                                                           checked={bilAddrIsShipAddr} onChange={(e, {checked}) => {
                                                setBillAddrIsShipAddr(checked!)
                                                setShippingOpen(false)
                                            }}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Title>
                            <AddressInfoForm visible={shippingOpen} address={shipAddr}
                                             onSetAddress={updateShippingAddress}
                                             onCancel={() => setShippingOpen(false)}
                                hideAddr={bilAddrIsShipAddr}
                            />
                        </Accordion>
                    </Form>
                </Card.Content>
                <Card.Content textAlign={"right"}>
                    <Button onClick={resetShopperInfo} disabled={!dirty}
                            color={'red'}>{shopperContext.translate('reset')}</Button>
                    <Popup openOnTriggerMouseEnter={true} disabled={!(billingOpen || shippingOpen)} trigger={
                        <span>
                        <Button onClick={updateShopperInfo} color={'blue'}
                                disabled={updating || billingOpen || shippingOpen || !dirty}
                                loading={updating}> {shopperContext.translate('updateCompanyInformation')} </Button>
                        </span>
                    }>{shopperContext.translate("finishEnteringAddressInfo")}</Popup>
                </Card.Content>
            </Card>
        </>
    )
}