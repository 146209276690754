import {useEffect, useState} from 'react';
import SplashPage from "./splash/SplashPage";
import App from "./App";
import {InitializeRayGun} from "./utils/WindowUtils";
import {Auth, Hub} from "@ametektci/ametek.stcappscommon";
import {UserContextWrapper} from "./contexts/UserContext";
import {APIContextWrapper} from "./contexts/APIContext";
import {LanguageContextWrapper} from "./contexts/LanguageContext";

export default function AppWithAuth() {
    //States:
    const [loggedIn, setLoggedIn] = useState(false)
    //effects:
    useEffect(() => {
        InitializeRayGun();
        Hub.listen("auth", (data) => getLogInState(data.payload.event))
        getLogInState("login")
    }, [])

    const getLogInState = (state: string) => {
        if (["login", "signIn", "signOut", "signUp", "verify"].includes(state))
            Auth.currentAuthenticatedUser().then(() => {
                setLoggedIn(true)
            }).catch(() => {
                setLoggedIn(false)
            })
    }

    if (!loggedIn)
        return <SplashPage/>
    else
        return (
            <LanguageContextWrapper>
                <APIContextWrapper>
                    <UserContextWrapper>
                        <App/>
                    </UserContextWrapper>
                </APIContextWrapper>
            </LanguageContextWrapper>
        )

}