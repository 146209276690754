import {Button, Form, Message, Segment} from "semantic-ui-react";
import {useContext, useState} from "react";
import {LanguageContext} from "../contexts/LanguageContext";
import {APIContext} from "../contexts/APIContext";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../contexts/UserContext";

export function CreateOrganization() {
    const translate = useContext(LanguageContext)
    const api = useContext(APIContext)
    const userContext = useContext(UserContext)
    const [orgName, setOrgName] = useState("")
    const navigate = useNavigate()
    const [inProgress, setInProgress] = useState(false)
    const [error, setError] = useState('')
    const createOrg = () => {
        if (!orgName)
            return
        api.CreateOrg(orgName).then(([success, message]) => {
                if (success)
                {
                    userContext.reload().then(() => navigate("/"))
                }
                else
                {
                    setInProgress(false)
                    setError(message)
                }
            }
        )
    }
    let errorMessage = ""
    switch (error) {
        case "":
            errorMessage = ""
            break
        case "BAD_CODE":
            errorMessage = translate.getString("requestANewLink")
            break
        case "NEEDS_ADMIN":
        case "REQUIRES_CLEANUP":
            errorMessage = translate.getString("assignAnotherAdminFirst")
            break
        default:
            errorMessage = translate.getString("unknownProblem")
            break
    }
    return (
        <Segment basic>
            <div>
                {translate.getString("createOrganizationInstructions")}
            </div>
            <br/>
            <Form>
                <Form.Group widths={"equal"}>
                    <Form.Input
                        value={orgName}
                        onChange={(e, {value}) => setOrgName(value)}
                        label={translate.getString('organizationName')}
                        labelPosition={"left"}
                        fluid
                        autoFocus
                    />
                    <Form.Button color={"blue"} label={"\u00A0"} onClick={() => createOrg()} disabled={orgName.length < 1 || inProgress}
                                 loading={inProgress}>{translate.getString("createNewOrganization")}</Form.Button>
                </Form.Group>
            </Form>
            <Message error content={errorMessage} hidden={errorMessage == ""}/>
            {userContext.organizationName ?
                <Button onClick={() => navigate("/")}>{translate.getString("cancelSwitch")}</Button> :
                null
            }

        </Segment>
    )
}