import {JSX, PropsWithChildren, useContext, useEffect, useState} from "react";
import {Button, Header, Message, Modal} from "semantic-ui-react";
import { useNavigate } from 'react-router-dom';
import {LanguageContext} from "../contexts/LanguageContext";
import {UserContext} from "../contexts/UserContext";

export function VerifySwitch(props: PropsWithChildren) {
    const translate = useContext(LanguageContext)
    const userContext = useContext(UserContext)
    const navigate = useNavigate()
    const [ready, setReady] = useState(userContext.accessLevel == "newUser")
    useEffect(() => {
        if (userContext.accessLevel == "")
            return; //We are not yet loaded. Abort.
        if (userContext.accessLevel != "newUser")
            return; //We have organization information
        setReady(true) //default to allowing switching organizations if the user is not in an organization.
    },[userContext, userContext.accessLevel])
    
    if (!ready)
        return (<Modal open={true}>
            <Modal.Header>
                <Header>{translate.getString("switchOrganizationsHeader")}</Header>
            </Modal.Header>
            <Modal.Content>
                <Message warning={true} style={{textAlign: "center"}}>{translate.getString("rejoinWarning")
                    .replace("**USER**", userContext.user?.name?.replaceAll(" ", " ") ?? translate.getString("loading"))
                    .replace("**EMAIL**", userContext.user?.email ?? translate.getString("loading"))
                    .replace("**ORG**", userContext.organizationName?.replaceAll(" ", " ") ?? translate.getString("loading"))
                }</Message>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => navigate("/")}>
                    {translate.getString("cancel")}
                </Button>
                <Button color={"red"} onClick={() => {
                    setReady(true)
                }}>
                    {translate.getString("okay")}
                </Button>
            </Modal.Actions>
        </Modal>)
    return props.children as JSX.Element
}