import {GaugeBase} from "../GaugeBase";
import model from '../../images/gauges/xp2i/picture.png';
import {PeaksAndAveraging} from "../Config/PeaksAndAveraging";
import {TemperatureUnit} from "../units";
import {Gauge} from "@ametektci/ametek.stcappscommon";
export class XP2I implements GaugeBase {
    constructor(item: Gauge | GaugeBase) {
        this.organizationId = item.organizationId
        this.connected = item.connected
        this.dataLogEnabled = item.dataLogEnabled
        this.connectedDeviceAgentVersion = item.connectedDeviceAgentVersion
        this.gaugeId = item.gaugeId
        this.model = item.model
        this.serialNumber = item.serialNumber
        this.calibrationDate = item.calibrationDate
        this.calibrationDue = item.calibrationDue
        this.deviceAgentConnectionId = item.deviceAgentConnectionId
        this.firmwareVersion = item.firmwareVersion
        this.lastSeen = item.lastSeen
        this.runTags = item.runTags
        this.modelId = item.modelId
        this.supportsLogging = false; //XP2I Logs are not pre-formatted as CSV, and support for their logs has not yet been built.
        this.dataLogPurchased = item.dataLogPurchased
        return this
    }
    organizationId: number
    peaksAndAveraging?: PeaksAndAveraging
    connected: boolean;
    connectedDeviceAgentVersion: any;
    dataLogEnabled: boolean;
    gaugeId: number;
    serialNumber: string;
    model: { name: string, shortName: any, liveLoggingCapable: boolean };
    modelId: number
    singleSensor = true
    picture = () => {
        return model
    }
    supportedTemps  = () : Array<TemperatureUnit> => {
        return ["4C", "60F", "68F"]
    }
    calibrationDate: any;
    calibrationDue: any;
    deviceAgentConnectionId: any;
    firmwareVersion: string;
    lastSeen: string;
    runTags?: string;
    supportsLogging: boolean;
    dataLogPurchased: boolean;
}