import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {User} from "../Models/User";
import {authenticationStore, managementConsoleStore, messageQueue, Utils} from "@ametektci/ametek.stcappscommon";
import axios, {InternalAxiosRequestConfig} from "axios";
import polly from "polly-js";
import localeStore, {
    GraphColorsLocalStorageKey,
    LanguageLocalStorageKey,
    SupportedLanguages,
    XAxisFormatLocalStorageKey
} from "../language/LocaleStore";
import textResources, {Language} from "@ametektci/ametek.stcappscommon/src/language/TextResources";
import rg4js from "raygun4js";
import {colorOptions, xAxisFormat} from "../logs/GraphSettings";
import {Unit} from "../utils/PressureValue";
import {APIContext} from "./APIContext";
//Linter was having trouble with seeing the false as a boolean type rather than as a false only type.
export const UserContext = createContext({
    user: null as User | null,
    accessLevel: "",
    organizationName: "",
    reload: () => Promise.resolve(),
    //settings
    pressureUnit:"psi" as Unit,
    graphUnit: null as Unit|null,
    setGraphUnit: (unit : Unit | null) => {},
    temperatureUnit:'°F',
    decimalSeparator:'us' as 'us'|'si',
    numberLocale:  'en-US' as  'en-US'|'fr-FR',
    graphColors: colorOptions[0].colors,
    dragToZoom: false as boolean, 
    onSetDragToZoom: (drag: boolean | null) => {},
    graphColorCode: 1 as number,
    setGraphColors: (colors: number) => {},
    xAxisFormat: 'us' as xAxisFormat,
    setXAxisFormat: (format : xAxisFormat) => {}, 
    
    language: 'english' as Language,
    setLanguage: (lang: Language) => {},
    updateUserLocaleSettings: (newPressure?: Unit, newTemperature?: string, newSeparator?: 'si'|'us') => Promise.resolve(),
    //permissions and privelages
    hasPermission: (permission: string) => false as boolean,
    hasSubscription: () => false as boolean,
    inTrial: () => false as boolean,
    subscriptionExpired: () => false as boolean,
    getSubscriptionTier: () => "" as string,
})
const GraphUnitLocalStoreString = "graphUnit"
export function UserContextWrapper(props: PropsWithChildren) {
    const api = useContext(APIContext)
    const [user, setUser] = useState<User | null>(null)
    const [accessLevel, setAccessLevel] = useState("")
    const [organizationName, setOrganizationName] = useState("")
    const [language, setLanguage] = useState<Language>("english")
    const managementAxios = axios.create({
        baseURL: api.managementConsoleApi,
    })
    const generateAuthHeader = async (request: InternalAxiosRequestConfig) => {
        request.headers.Authorization = "bearer " + await authenticationStore.getAccessToken()
        return request
    }
    managementAxios.interceptors.request.use(generateAuthHeader)
    const browserLang = window.navigator.language;
    const defaultPressure = () => {
        if (browserLang.startsWith('zh'))
            return 'kpa'
        if (browserLang == 'en-US')
            return 'psi'
        return 'bar'
    }
    const [pressureUnit, setPressureUnit] = useState<Unit>(defaultPressure())
    const [graphUnit, setGraphUnit] = useState<Unit | null>(localStorage.getItem(GraphUnitLocalStoreString) as Unit?? null)
    const defaultTemp = () => browserLang === 'en-US' ? '°F' : '°C';
    const [temperatureUnit, setTemperatureUnit] = useState<string>(defaultTemp())
    const [decimalSeparator, setDecimalSeperator] = useState<'si' | 'us'>(localeStore.language == "english"? "us" : "si")
    const [colorCode, setColorCode] = useState(1)
    const [xAxisFormat, setXAxisFormat] = useState<xAxisFormat>('us')
    const [dragToZoom, setDragToZoom] = useState(localStorage.getItem("dragToZoom") === "true")
    useEffect(() => {
        getGraphColorCode()
        getXAxisFormat()
    },[])
    useEffect(() => {
        if (user == null)
            return
        if (user.pressureUnit)
            setPressureUnit(user.pressureUnit.toLowerCase() as Unit)
        if (user.temperatureUnit)
            setTemperatureUnit(user.temperatureUnit)
        if (user.decimalSeparator)
            setDecimalSeperator(user.decimalSeparator)
        localeStore.setUserProfilePreferences(user);
        rg4js('setUser', {
            identifier: user.username,
            isAnonymous: false
        });
    }, [user])
    const changeLanguage = (lang: Language) => {
        if (! SupportedLanguages.some(l => l === language))
            throw new Error("Language not supported");
        setLanguage(lang)
        localStorage.setItem(LanguageLocalStorageKey, language);
        localeStore.language = language
        updateUserLocaleSettings(pressureUnit, temperatureUnit, decimalSeparator, language).then(() => {
            // set the moment date locale
            localeStore.setDateLocale(language);
            //set strings
            localeStore.setStrings()
            textResources.setCurrentLanguage(lang)
            Utils.ReloadPage();
        });
    }
    const updateUserLocaleSettings = async (newPressureUnit = pressureUnit, newTemperatureUnit = temperatureUnit, separator = decimalSeparator, lang?: Language) => {
        setDecimalSeperator(separator)
        localeStore.decimalSeparator = separator
        setPressureUnit(newPressureUnit)
        setTemperatureUnit(newTemperatureUnit)
        await managementAxios.post("/SetLocalePreferences", {
                Language: lang ?? localeStore.language,
                DecimalSeparator: separator,
                PressureUnit: newPressureUnit,
                TemperatureUnit: newTemperatureUnit
            }).catch(error => {
            console.log(error);
            messageQueue.sendError(localeStore.Strings.errorSettingLocalePreferences);
        });
    }
    
    
    useEffect(() => {
        authenticationStore.on('authenticationSuccessfulEvent', reload);
        authenticationStore.on('signOutEvent', reload);
        managementConsoleStore.on('organizationJoinedEvent', reload);
        managementConsoleStore.on('organizationCreatedEvent', reload)
        return () => {
            authenticationStore.off('authenticationSuccessfulEvent', reload);
            authenticationStore.off('signOutEvent', reload);
            managementConsoleStore.off('organizationJoinedEvent', reload);
            managementConsoleStore.off('organizationCreatedEvent', reload)
        }
    })
    const reload = async () => {
        
        let response = await polly()
            .logger(err => {
                if (err == "No current user")
                    return {data: {
                        user: null,
                            accessLevel: "",
                            organizationName: "",
                        }} //If a user is signed out, we should provide reasonable "nothing" options.
                console.error("Error loading organization gauges", err)
            })
            .handle((err) => {
                if (err.response) {
                    return err.response.status !== 403 && err.response.status !== 404
                }
                return true;
            })
            .waitAndRetry([50,100,200])
            .executeForPromise( async () => {
                return await managementAxios.post("/Application", {applicationName: "crystalcontrolweb"})
            })
        /*
        public User User { get; set; }
        public string AccessLevel { get; set; }
        public string OrganizationName { get; set; }
         */
        setUser(response.data.user)
        setAccessLevel(response.data.accessLevel)
        setOrganizationName(response.data.organizationName)
    }

    const checkInPermittedLevels_ = (permittedLevels: Array<string>) => {
        return permittedLevels.some(level => accessLevel === level)
    };
    /*
        Verify that a user's access level allows for a particular task
     */
    const hasPermission = (permissionName : string) => {
        switch (permissionName) {
            case PermissionDescriptions.CreateSubscription:
                return user?.isAdmin ?? false;
            case PermissionDescriptions.OfflineClient:
                return true //this.checkInPermittedLevels_([accessLevels_.trial, accessLevels_.advanced]);
            default:
                console.log('Unknown Permission', permissionName);
                return false;
        }
    };
    const hasSubscription = () => checkInPermittedLevels_([accessLevels_.advanced, accessLevels_.basic]);
    const inTrial = () => accessLevel === 'trial';
    const subscriptionExpired = () => accessLevel === 'subscriptionCancelled';
    const getSubscriptionTier = () => accessLevel;
    const getXAxisFormat = () => {
        setXAxisFormat(localStorage.getItem(XAxisFormatLocalStorageKey) || 'us')
    }
    const setCachedXAxisFormat = (format: xAxisFormat) => {
        setXAxisFormat(format)
        localStorage.setItem(XAxisFormatLocalStorageKey, format);
    }
    const graphColors = colorOptions.find(col => col.value == colorCode)?.colors ?? colorOptions[0].colors
    const getGraphColorCode = () => {
        let colors = localStorage.getItem(GraphColorsLocalStorageKey)
        try
        {
            let code = Number.parseInt(colors ?? "1")
            if (Number.isNaN(code))
                code = 1
            setColorCode(code)
        }
        catch
        {
            setColorCode(1)
        }
    }
    const onSetDragToZoom = (drag: boolean | null) => {
        if (drag == null)
            localStorage.removeItem("dragToZoom")
        else
            localStorage.setItem("dragToZoom", drag.toString())
        setDragToZoom(drag ?? false)
    }
    const onSetGraphUnit = (unit: Unit | null) => {
        if (unit == null)
            localStorage.removeItem(GraphUnitLocalStoreString)
        else
            localStorage.setItem(GraphUnitLocalStoreString, unit ?? "")
        setGraphUnit(unit)
    }
    const setGraphColors = (colors: number) => {
        setColorCode(colors)
        localStorage.setItem(GraphColorsLocalStorageKey, colors.toString());
    }
    
    return (
        <UserContext.Provider value={{
            user, accessLevel, organizationName,
            reload,
            pressureUnit, graphUnit, temperatureUnit, decimalSeparator,
            setGraphUnit: onSetGraphUnit,
            graphColors, setGraphColors, graphColorCode: colorCode,
            xAxisFormat, setXAxisFormat:setCachedXAxisFormat,
            dragToZoom, onSetDragToZoom,
            language: "english", setLanguage: changeLanguage,
            updateUserLocaleSettings,
            numberLocale: decimalSeparator === 'si' ? 'fr-FR' : 'en-US',
            hasPermission,
            hasSubscription,
            inTrial,
            subscriptionExpired,
            getSubscriptionTier,
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

const accessLevels_ = {
    trial : 'trial',
    basic : 'Standard',
    advanced : 'Advanced'
};

export const PermissionDescriptions = {
    CreateSubscription : "CreateSubscriptionPermission",
    UploadLogs : "uploadDataFromPc",
    ManageLogs : "loggedDataManagement",
    CreatePdf : "securePdfExport",
    OfflineClient : "OfflineClientPermission",
};